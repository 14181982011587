import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <div className="dib relative w-100 h-100-ns">
      <div className="db center mw8 ph3 pv7 tc f4">
        <h1 className="db center measure-wide f2 f1-l">NOT FOUND</h1>
        <div className="dib relative w-100">
          <p className="db center measure-wide">
            You just hit a route that doesn't exist... the sadness.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
